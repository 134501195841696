import React, {useRef} from "react";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero3 from "../../components/SectionHero/SectionHero3";


function PageHome() {
  const featuredSection = useRef(null);
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
        {/* SECTION HERO */}
        <div className="container px-1 sm:px-4 mb-24 ">
            <SectionHero3 className="" featuredSection={featuredSection} />
        </div>
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">


        {/* featured places to stay */}
        <div className="relative py-16" >
          <BackgroundSection />
          <SectionGridFeaturePlaces />
            <div ref={featuredSection}></div>
        </div>

        {/* One stop shop.. */}
        <SectionOurFeatures />

        {/* explore nearby
        <SectionGridCategoryBox />
        */}


          {/* newsletter */}



        {/* explore types of stay
         <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        />


         */}


        {/* feedbacks
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
        */}
      </div>
    </div>
  );
}

export default PageHome;
