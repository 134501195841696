import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";


const otherPageChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog Page",
    admin: true,
    protected:true
  },
  {
    id: ncNanoId(),
    href: "/blog-single",
    name: "Blog Single",
      admin: true,
      protected:true
  },
  {
    id: ncNanoId(),
    href: "/subscription",
    name: "Subscription",
      admin: true,
      protected:true
  },
];

const templatesChildrenMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/add-listing-1",
    name: "Add Listings",
    type: "dropdown",
    protected:true,
    children: [
      {
        id: ncNanoId(),
        href: "/add-listing-1",
        name: "Add Listings 1",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-2",
        name: "Add Listings 2",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-3",
        name: "Add Listings 3",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-4",
        name: "Add Listings 4",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-5",
        name: "Add Listings 5",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-6",
        name: "Add Listings 6",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-7",
        name: "Add Listings 7",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-8",
        name: "Add Listings 8",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-9",
        name: "Add Listings 9",
      },
      {
        id: ncNanoId(),
        href: "/add-listing-10",
        name: "Add Listings 10",
      },
    ],
  },
  //
  { id: ncNanoId(), href: "/checkout", name: "Checkout" },
  { id: ncNanoId(), href: "/pay-done", name: "Pay done" },
  //
  { id: ncNanoId(), href: "/author", name: "Author Page", admin: true, protected:true  },
  { id: ncNanoId(), href: "/account", name: "Account Page", admin: true, protected:true },
];

export const navigationMenu: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/listing-stay-map",
    name: "Listing Page",
    type: "dropdown",
    isNew: true,
    admin: true,
    protected:true,
    children: [
      {
        id: ncNanoId(),
        href: "/listing-stay-map",
        name: "Stay listing",
        type: "dropdown",
        children: [
          { id: ncNanoId(), href: "/listing-stay-map", name: "Stay page" },
          {
            id: ncNanoId(),
            href: "/listing-stay-map",
            name: "Stay page (map)",
          },
          { id: ncNanoId(), href: "/listing-stay-detail", name: "Stay Detail" },
        ],
      },

    ],
  },
  {
    id: ncNanoId(),
    href: "/author",
    name: "Templates",
    type: "dropdown",
    children: templatesChildrenMenus,
    admin: true,
    protected:true,
  },

  {
    id: ncNanoId(),
    href: "/blog",
    name: "Other pages",
    type: "dropdown",
    children: otherPageChildMenus,
    admin: true,
    protected:true,
  },
    {
        id: ncNanoId(),
        href: "/about",
        name: "About",
    },
    {
        id: ncNanoId(),
        href: "/contact",
        name: "Contact us",
    },
    {
        id: ncNanoId(),
        href: "/login/login",
        name: "Login",
        hideifloggedin:true,
    },
    {
        id: ncNanoId(),
        href: "/login/signup",
        name: "Signup",
        hideifloggedin:true,
    },
];
