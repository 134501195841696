import React from 'react';
import PagesInfo from "./PagesInfo";
const termsAndConditions: { title: string; content?: string, bits?:any[] }[] = [
    {
        title: "1. Definitions",
        bits: [
            `"@Ballito": The terms "@Ballito", "ourselves", "our", "us" and "we" refer to the company AtBallito (Pty) Ltd (Reg no 2024/353228/07) whose physical address is 36 Madeleine Drive, Ballito, 4399, South Africa.`,
            `"Website": Refers to web and phone app based platforms run by @Ballito, including all associated services.`,
            `"Client": Refers to any person or entity using the Website to book a holiday or related services.`,
            `"Property Owner": Refers to any person or entity listing a property for rent on the Website.`,
            `"Booking": Refers to the reservation of a property or related service made by a Client.`,
            `"Listing": Refers to the details of a property made available by a Property Owner on the Website.`
        ]
    },
    {
        title: "2. Eligibility",
        bits: [
            `Clients: Must be at least 18 years old and have the legal capacity to enter into a binding contract.`,
            `Property Owners: Must have the legal right to rent the property listed and be able to provide the services as described in their Listing.`
        ]
    },
    {
        title: "3. Account Registration",
        bits: [
            `Account Creation: Clients and Property Owners must create an account to use the Website's services. You agree to provide accurate, current, and complete information during registration.`,
            `Account Security: You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account.`
        ]
    },
    {
        title: "4. Property Listings",
        bits: [
            `Accuracy of Listings: Property Owners are responsible for ensuring that their Listings are accurate, up-to-date, and complete. This includes information such as availability, pricing, and property description.`,
            `Compliance with Laws: Property Owners must ensure that their Listings comply with all local laws, regulations, and zoning ordinances.`,
            `Prohibited Listings: Property Owners may not list properties that are not legally permissible to rent, or that infringe on the rights of others.`
        ]
    },
    {
        title: "5. Bookings and Payments",
        bits: [
            `Booking Process: Clients can book properties through the Website. Once a Booking is made, it is a legally binding agreement between the Client and the Property Owner.`,
            `Payment Terms: Payments are processed through our payment gateway. Clients are required to pay the full amount at the time of Booking unless otherwise agreed upon.`,
            `Cancellation Policy: Each Listing will have its own cancellation policy. Clients should review this policy before making a Booking. Refunds, if applicable, will be processed according to the terms set out in the cancellation policy.`
        ]
    },
    {
        title: "6. Fees",
        bits: [
            `Service Fees: We may charge service fees for using the Website, which will be disclosed at the time of payment.`,
            `Payment Processing Fees: Payment processing fees may apply, which will be borne by either the Client or the Property Owner as indicated during the transaction process.`
        ]
    },
    {
        title: "7. Responsibility and Liability",
        bits: [
            `Property Owners: Are solely responsible for their Listings and for providing the property as described. Property Owners agree to indemnify and hold harmless the Website from any claims arising out of their Listings.`,
            `Clients: Are responsible for their behavior during their stay and must comply with any house rules set by the Property Owner. Clients agree to indemnify and hold harmless the Website from any claims arising out of their use of the property.`,
            `Website's Liability: The Website acts as a platform connecting Clients and Property Owners. We are not responsible for any disputes between Clients and Property Owners and are not liable for any damages or losses incurred from such disputes.`
        ]
    },
    {
        title: "8. Dispute Resolution",
        bits: [
            `Between Clients and Property Owners: Any disputes arising from a Booking or Listing should be resolved between the Client and the Property Owner. We may offer mediation services but are not obligated to do so.`,
            `Website's Role: We may intervene in disputes at our discretion but are not responsible for resolving such disputes.`
        ]
    },
    {
        title: "9. Termination",
        bits: [
            `By the Website: We reserve the right to suspend or terminate your account at our discretion, without notice, for any reason, including violation of these Terms.`,
            `By the User: You may terminate your account at any time. However, any obligations incurred before termination will remain in effect.`
        ]
    },
    {
        title: "10. Intellectual Property",
        bits: [
            `Ownership: All content, trademarks, and intellectual property on the Website are owned by @Ballito or our licensors. You may not use, copy, or distribute any content from the Website without our express permission.`
        ]
    },
    {
        title: "11. Privacy Policy",
        bits: [
            `Data Collection: We collect and use personal data in accordance with our https://atballito.co.za/privacy-policy`,
            `Data Sharing: We may share your information with third parties as necessary to provide our services.`
        ]
    },
    {
        title: "12. Modifications to Terms",
        content: `We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the Website. Your continued use of the Website after such modifications constitutes your acceptance of the new Terms.`
    },
    {
        title: "13. Governing Law",
        content: `These Terms shall be governed by and construed in accordance with the laws of KwaDukuza, South Africa. Any disputes arising from these Terms shall be subject to the exclusive jurisdiction of the courts in KwaDukuza, South Africa.`
    },
    {
        title: "14. Contact Information",
        content: `For any questions or concerns regarding these Terms, please contact us at legal@atballito.co.za`
    }
];
const TermsAndConditions: React.FC = () => {
    return (
        <PagesInfo heading='Terms and Conditions' sections={termsAndConditions}/>
    );
};

export default TermsAndConditions;
