import React from 'react';
import PagesInfo from "./PagesInfo";
const privacyPolicy: { title: string; content: string; bits?:any[] }[] = [
    {
        title: "1. Introduction",
        content: `This Privacy Policy explains how @Ballito ("we", "us", or "our") collects, uses, and discloses information about you when you use our website and services ("Services"). By using our Services, you agree to the collection and use of information in accordance with this policy.`
    },
    {
        title: "2. Information We Collect",
        content: `We collect several types of information to provide and improve our Services:`,
        bits:[
            `Personal Information: When you create an account, book a property, or list a property, we may collect personal information such as your name, email address, phone number, payment information, and any other information you provide to us.`,
            `Usage Data: We may collect information on how you access and use the Website, including your IP address, browser type, pages visited, and other diagnostic data.`,
            `Cookies and Tracking Technologies: We use cookies and similar tracking technologies to monitor activity on our Website and store certain information.`,
            ]
    },
    {
        title: "3. How We Use Your Information",
        content: `We use the information we collect for various purposes, including:`,
        bits:[
            `To Provide and Maintain Our Services: We use your personal information to process transactions, manage your account, and provide customer support.`,
            `To Improve Our Services: We analyze usage data to improve our Website's functionality and user experience.`,
            `To Communicate with You: We may use your information to send you updates, promotional materials, and other information that may be of interest to you.`,
            `To Comply with Legal Obligations: We may use your information to comply with applicable laws and regulations.`
            ]
    },
    {
        title: "4. Information Sharing and Disclosure",
        content: `We may share your information in the following circumstances:`,
        bits:[
            `With Service Providers: We may share your information with third-party service providers who assist us in operating our Website and providing our Services.`,
            `For Legal Reasons: We may disclose your information if required to do so by law or in response to valid requests by public authorities.`,
            `In Business Transfers: If we are involved in a merger, acquisition, or asset sale, your information may be transferred as part of that transaction.`
            ]
    },
    {
        title: "5. Data Security",
        content: `We take the security of your personal information seriously and implement reasonable measures to protect it. However, please note that no method of transmission over the Internet or method of electronic storage is completely secure, and we cannot guarantee absolute security.`
    },
    {
        title: "6. Your Data Protection Rights",
        content: `Depending on your location, you may have the following rights regarding your personal information:`,
        bits:[
            `Access: You have the right to request a copy of the personal information we hold about you.`,
            `Rectification: You have the right to request that we correct any inaccuracies in your personal information.`,
            `Erasure: You have the right to request the deletion of your personal information under certain circumstances.`,
            `Restriction: You have the right to request that we restrict the processing of your personal information.`,
            `Data Portability: You have the right to request that we transfer your personal information to another organization, or directly to you, under certain conditions.`,
            `Objection: You have the right to object to our processing of your personal information under certain circumstances.`
            ]
    },
    {
        title: "7. Cookies",
        content: `We use cookies and similar tracking technologies to enhance your experience on our Website. You can control the use of cookies through your browser settings. However, if you disable cookies, some parts of the Website may not function properly.`
    },
    {
        title: "8. Third-Party Links",
        content: `Our Website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these third-party sites. We encourage you to read the privacy policies of any third-party websites you visit.`
    },
    {
        title: "9. Children's Privacy",
        content: `Our Services are not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have collected personal information from a child under 18, we will take steps to delete such information.`
    },
    {
        title: "10. Changes to This Privacy Policy",
        content: `We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Changes are effective immediately upon posting. Your continued use of the Website after any changes constitutes your acceptance of the new terms.`
    },
    {
        title: "11. Contact Us",
        content: `If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at https://atballito.co.za/contact`
    }
];
const PagePrivacy: React.FC = () => {
    return (
        <PagesInfo heading='Privacy Policy' sections={privacyPolicy}/>
    );
};

export default PagePrivacy;