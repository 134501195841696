import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { useSearch} from "../../context/SearchContext";

export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
}

const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/listing-stay-map",
  hasButtonSubmit = true,
}) => {
    const { guestAdultsInputValue,guestChildrenInputValue,guestInfantsInputValue, guestHandleChangeData} = useSearch();

  //const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(2);
  //const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(1);
  //const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(1);

  // const handleChangeData = (value: number, type: keyof GuestsObject) => {
  //   let newValue = {
  //     guestAdults: guestAdultsInputValue,
  //     guestChildren: guestChildrenInputValue,
  //     guestInfants: guestInfantsInputValue,
  //   };
  //   if (type === "guestAdults") {
  //     setGuestAdultsInputValue(value);
  //     newValue.guestAdults = value;
  //   }
  //   if (type === "guestChildren") {
  //     setGuestChildrenInputValue(value);
  //     newValue.guestChildren = value;
  //   }
  //   if (type === "guestInfants") {
  //     setGuestInfantsInputValue(value);
  //     newValue.guestInfants = value;
  //   }
  // };

  const totalGuests =
      (guestChildrenInputValue ?? 0) + (guestAdultsInputValue ?? 0) + (guestInfantsInputValue ?? 0);

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} Guests
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests ? "Guests" : "Add guests"}
                </span>
              </div>

              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    if (guestHandleChangeData) {
                        guestHandleChangeData(0, "guestAdults");
                        guestHandleChangeData(0, "guestChildren");
                        guestHandleChangeData(0, "guestInfants");
                    }
                  }}
                />
              )}
            </Popover.Button>

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit href={buttonSubmitHref} />
              </div>
            )}
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={guestAdultsInputValue}
                onChange={(guestHandleChangeData
                    ? (value) => guestHandleChangeData(value, "guestAdults")
                    : undefined)}
                max={0}
                min={1}
                label="Adults"
                desc="Ages 13 or above"
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestChildrenInputValue}
                onChange={(guestHandleChangeData
                    ? (value) => guestHandleChangeData(value, "guestChildren")
                    : undefined)}
                max={10}
                label="Children"
                desc="Ages 2–12"
              />

              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestInfantsInputValue}
                onChange={(guestHandleChangeData
                    ? (value) => guestHandleChangeData(value, "guestInfants")
                    : undefined)}
                max={10}
                label="Infants"
                desc="Ages 0–2"
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
