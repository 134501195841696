import React, {useEffect} from "react";
import MyRouter from "routers/index";
import ReactGA from "react-ga4";

ReactGA.initialize("G-BBJ0RWL32K");

function App() {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <MyRouter />
    </div>
  );
}

export default App;
