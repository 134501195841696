import {
  AdvancedMarker,
  ControlPosition,
  Map,
  MapControl,
} from "@vis.gl/react-google-maps";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import { FC } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import {PropertyType} from "../data/propertiesDataHook";

interface MapContainerProps {
  currentHoverID: string | number;
  propData: PropertyType[];
  listingType: "stay";
}

const MapContainer: FC<MapContainerProps> = ({
  currentHoverID = -1,
  propData,
  listingType,
}) => {
  return (
    <>
      <Map
        style={{
          width: "100%",
          height: "100%",
        }}
        defaultZoom={13}
        defaultCenter={propData.length > 0 ? ( propData[0].map ) : ( { "lat": -29.52052, "lng": 31.22664 } )}
        gestureHandling={"greedy"}
        mapId={process.env.REACT_APP_GOOGLE_MAP_ID}
      >
        <MapControl position={ControlPosition.TOP_CENTER}>
          <div className="mt-5 py-2 px-4 bg-neutral-100 dark:bg-neutral-900 shadow-xl z-10 rounded-2xl min-w-max">
            <Checkbox
              className="text-xs xl:text-sm text-neutral-800"
              name="search_as_i_move"
              label="Search as I move the map"
            />
          </div>
        </MapControl>
        {propData ? ( propData.map((item) => (
          <AdvancedMarker
            key={item.id}
            position={item.map}
            clickable
            onClick={() => console.log("clicked")}
          >
            <AnyReactComponent
              isSelected={currentHoverID === item.id}
              key={item.id}
              lat={item.map.lat}
              lng={item.map.lng}
              listing={(item as PropertyType)}
            />
          </AdvancedMarker>
        )) ) : ( '' )}
      </Map>
    </>
  );
};

export default MapContainer;
