import {log,err} from "../hooks/sfw";

const cachedData: { [key: string]: any[] } = {};
const lastFetchTimes: { [key: string]: number } = {};
const cacheDuration = 60 * 10 * 1000; // Cache duration in milliseconds - 10 minutes

// Function to fetch data from the server
export async function fetchData(path: string): Promise<any[]> {
    // Check if cached data is valid and within cache duration
    //console.log(cachedData);console.log(lastFetchTimes);
    if (cachedData[path] && (!lastFetchTimes[path] || Date.now() - lastFetchTimes[path] < cacheDuration)) {
        log('datafetch used cache:'+path);
        return cachedData[path];
    }

    try {
        log('datafetch:' + path);
        const response = await fetch(process.env.REACT_APP_BACKEND_SERVER + '/api/'+path);
        log('datafetchresp:' + path, response);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data: any[] = await response.json();
        cachedData[path] = data;
        lastFetchTimes[path] = Date.now();

        return data;
    } catch (error) {
        err('Error fetching data:', error);
        throw error;
    }
}