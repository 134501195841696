import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import {usePropertyData,PropertyType} from "../../data/propertiesDataHook";

//
export interface SectionGridFeaturePlacesProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that @Ballito recommends for you",
  headingIsCenter,
  tabs = ["Ballito"],
}) => {
  const renderCard = (stay: PropertyType) => {
    return <StayCard key={stay.id} data={stay} />;
  };
    const {propData} = usePropertyData();
  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"Ballito"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
          {propData ? (
              propData.map((stay) => renderCard(stay))
          ) : (
              <p>Loading data...</p>
          )}

      </div>
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary  href="/listing-stay-map" loading>Show me more</ButtonPrimary>

      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
