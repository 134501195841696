import { useEffect, useState } from 'react';
import { fetchData } from './dataFetcher';

export interface SeasonsType {
    start: string;
    end: string;
    desc: 'out' | 'semi' | 'in' | 'peak';
}

export const useSeasonsData = (): SeasonsType[] => {
    //console.log('tdhook:town data comp fired..');
    const [seasonData, setData] = useState<SeasonsType[]>([]);
    useEffect(() => {
        const fetchDataFromServer = async () => {
            try {
                const data = await fetchData('seasons');
                setData(data);
            } catch (error) {
                // Handle error fetching data
                console.error('Error fetching data in seasons component:', error);
            }
        };
        fetchDataFromServer();
    }, []);

    return seasonData;
};

