import { useEffect, useState } from 'react';
import { fetchData } from './dataFetcher';

interface PropBitsType {
    pd_id: number;
    pd_category: string;
    pd_name: string;
    pd_icons: string;
}

export const usePropBits = (): PropBitsType[] => {
    //console.log('pbhook:propbits data comp fired..');
    const [propBits, setData] = useState<PropBitsType[]>([]);
    useEffect(() => {
        const fetchDataFromServer = async () => {
            try {
                const data = await fetchData('prop_bits');
                setData(data);
            } catch (error) {
                // Handle error fetching data
                console.error('Error fetching data in component:', error);
            }
        };

        fetchDataFromServer();
    }, []);

    return propBits;
};

