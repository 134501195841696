import React, { FC, useState } from "react";
import StayCardH from "components/StayCard/StayCardH";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import MapContainer from "containers/MapContainer";
import {usePropertyData} from "../../data/propertiesDataHook";

export interface SectionGridHasMapProps {}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const {filteredProperties} = usePropertyData();

    // const [iPLenThis, setPLenThis] = useState(0);
    // useEffect(() => {
    //     console.log('iPLen has changed', iPLen);
    //     setPLenThis(iPLen); // force re-render by changing state
    // }, [iPLen]); // dependency array

    //TODO: sort out the tabfilters, problem is the changes in the filter changes the array in propdatahook but that isnt causign a re-render for some reason. taking too long to fix so will continue without it for now..
    // TODO: maybe store the values in the searchcontext because that works for the other search filters so this will probably work. also makes sense for it to be in the searchcontext - been trying to store it in the hook itself and thats the flaw!!!
    /*

    iPLen:{iPLen}
  iPLenThis:{iPLenThis}
     */
  return (
    <div>
      <div className="relative flex min-h-screen">
        {/* CARDS */}
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2 />
            {/*<div className="mb-8 lg:mb-11">
                <TabFilters />
              </div>*/}
          <div className="grid grid-cols-1 gap-8">

            {filteredProperties ? (
                filteredProperties.map((item) => (
              <div
                key={item.id}
                onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}
              >
                <StayCardH data={item} />
              </div>
            ))
            )  : ( <p>Loading data...</p> )}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination />
          </div>
        </div>

        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}

        {/* MAP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            <MapContainer
              currentHoverID={currentHoverID}
              propData={filteredProperties ? (filteredProperties) : ([])}
              listingType="stay"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
