//import {Tab} from "@headlessui/react";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import React, {FC, FormEvent, useState} from "react";
//import visaPng from "images/vis.png";
//import mastercardPng from "images/mastercard.svg";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {
    calculateServiceFees,
    calculateTotalForNights,
    QuoteSummaryDisplay,
    useSearch
} from "../../context/SearchContext";
import { useParams} from "react-router-dom";
import {usePropertyData} from "../../data/propertiesDataHook";
import {toInteger, toNumber} from "lodash";
import {useAuth} from "../../context/AuthContext";
import PayPage from "../PayPage/PayPage";
import moment from "moment";

export interface CheckOutPagePageMainProps {
    className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
             className = "",
                                                             }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    let {id} = useParams<{ id?: string }>();
    //id = id || localStorage.getItem('checkOutPropertyId');
    if (id !== undefined) {
        localStorage.setItem('checkOutPropertyId',id);
    }else{
        let storedID = localStorage.getItem('checkOutPropertyId');
        id = storedID ? storedID : undefined;
    }
    const {propData} = usePropertyData();
    const thisProp = propData.find(e => e.id === toNumber(id));
    //console.log('checkout:thisprop:', thisProp);

    const {
        startDate,
        endDate,
        stayNights,
        guestAdultsInputValue,
        guestChildrenInputValue,
        guestInfantsInputValue,
        //setBookProperty,
        //bookProperty,
        aDays
    } = useSearch();

    //if (setBookProperty && thisProp != undefined) setBookProperty(thisProp);

    const {aUser,isLoggedIn,jwt} = useAuth();

    const [firstname, setFirstname] = useState(aUser.firstname);
    const [lastname, setLastname] = useState(aUser.lastname);
    const [email, setEmail] = useState(aUser.email);
    const [cell, setCell] = useState(aUser.phone);
    const [address, setAddress] = useState(aUser.address);
    const [addinfo, setAddinfo] = useState("");
    //const navigate = useNavigate();

    let iDaysTotal = (aDays && thisProp ? calculateTotalForNights(aDays,thisProp) : 0);
    let iService = calculateServiceFees(iDaysTotal);
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        //

        if (firstname && lastname && email && cell) {

            const response = await fetch(process.env.REACT_APP_BACKEND_SERVER + '/api/book', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jwt,
                    aUser,
                    firstname,
                    lastname,
                    email,
                    cell,
                    address,
                    addinfo,
                    thisProp,
                    startDate:moment(startDate).format('YYYY-MM-DD'),
                    endDate:moment(endDate).format('YYYY-MM-DD'),
                    stayNights,
                    guestAdultsInputValue,
                    guestChildrenInputValue,
                    guestInfantsInputValue,
                    totalQuote,
                    iDaysTotal,
                    nightRate,
                    iService,
                    rates_out: (thisProp?.ratesOutSeason ?? 0),
                    rates_semi: (thisProp?.ratesSemiSeason ?? 0),
                    rates_in: (thisProp?.ratesInSeason ?? 0),
                    rates_peak: (thisProp?.ratesPeakSeason ?? 0),
                    stay_out: (aDays?.out ?? 0),
                    stay_semi: (aDays?.semi ?? 0),
                    stay_in: (aDays?.in ?? 0),
                    stay_peak: (aDays?.peak ?? 0),
                }),
            });

            const responseData = await response.json();
            console.log(responseData);

            if (responseData.success && responseData.bookref) {
                //processLogin(responseData.user);
                //login(responseData);
                console.log('book successful');
                localStorage.setItem('checkOutPropertyId','');
                return (
                    <PayPage propId={id} bookRef={responseData.bookref} />
                );
                //navigate('/pay-done/'++'/'+);
            } else {
                console.log('book failed');
                setErrMessage('booking failed please try again or contact us on book@atballito.co.za')
            }
            setIsLoading(false);
        } else {
            setErrMessage('Please fill in all fields.');
            setIsLoading(false);
        }

    };

    const nightRate = thisProp?.ratesOutSeason.toLocaleString(undefined, {maximumFractionDigits: 0});

    //const totalForNights = (iDaysTotal).toLocaleString(undefined, {maximumFractionDigits: 0});
    const serviceFee = iService;
    const totalQuote = ((toInteger(thisProp?.ratesOutSeason ?? 0) * (stayNights ?? 1)) + toInteger(serviceFee)).toLocaleString(undefined, {maximumFractionDigits: 0});
    const renderSidebar = () => {
        return (
            <div
                className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
                <div className="flex flex-col sm:flex-row sm:items-center">
                    <div className="flex-shrink-0 w-full sm:w-40">
                        <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                            <img
                                alt=""
                                className="absolute inset-0 object-cover"
                                sizes="200px"
                                src={thisProp?.galleryImgs500[0]}
                            />
                        </div>
                    </div>
                    <div className="py-5 sm:px-5 space-y-3">
                        <div>
                          <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                            {thisProp?.address}
                          </span>
                            <span className="text-base font-medium mt-1 block">
                            {thisProp?.title}
                          </span>
                        </div>
                        <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                          {thisProp?.bedrooms} bedrooms · {thisProp?.bathrooms} bathrooms
                        </span>
                        <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                        <StartRating point={thisProp?.stars} reviewCount={thisProp?.reviewcount}/>
                    </div>
                </div>

                <QuoteSummaryDisplay
                    aDays={aDays}
                    thisProp={thisProp}></QuoteSummaryDisplay>
            </div>
        );
    };


    const renderMain = () => {
        return (
            <div
                className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <h2 className="text-3xl lg:text-4xl font-semibold">
                    Confirm your booking
                </h2>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div>
                    <div>
                        <h3 className="text-2xl font-semibold">Your trip</h3>
                        <NcModal
                            renderTrigger={(openModal) => (
                                <span
                                    onClick={() => openModal()}
                                    className="block lg:hidden underline  mt-1 cursor-pointer"
                                >
                  View booking details
                </span>
                            )}
                            renderContent={renderSidebar}
                            modalTitle="Booking details"
                        />
                    </div>
                    <div
                        className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
                        <ModalSelectDate
                            renderChildren={({openModal}) => (
                                <button
                                    onClick={openModal}
                                    className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                                    type="button"
                                >
                                    <div className="flex flex-col">
                                        <span className="text-sm text-neutral-400">Date</span>
                                        <span className="mt-1.5 text-lg font-semibold">
                      {
                          (startDate !== undefined && endDate !== undefined) ?
                              converSelectedDateToString([startDate, endDate])
                              : 'Add Date Range'
                      }
                    </span>
                                    </div>
                                    <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400"/>
                                </button>
                            )}
                        />

                        <ModalSelectGuests
                            renderChildren={({openModal}) => (
                                <button
                                    type="button"
                                    onClick={openModal}
                                    className="text-left flex-1 p-5 flex justify-between space-x-5 hover:bg-neutral-50 dark:hover:bg-neutral-800"
                                >
                                    <div className="flex flex-col">
                                        <span className="text-sm text-neutral-400">Guests</span>
                                        <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${
                            (guestAdultsInputValue || 0) +
                            (guestChildrenInputValue || 0)
                        } Guests, ${guestInfantsInputValue || 0} Infants`}
                      </span>
                    </span>
                                    </div>
                                    <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400"/>
                                </button>
                            )}
                        />
                    </div>
                </div>

                <div>
                    <h3 className="text-2xl font-semibold">Your details</h3>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
                    {
                        errMessage && (
                            <div className="ext-xl font-semibold text-red-400 ">{errMessage}</div>
                        )
                    }
                    {
                        isLoggedIn
                            ? <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={handleSubmit}>
                                <div className="mt-6">
                                    <div className="flex space-x-5  ">
                                        <div className="flex-1 space-y-1">
                                            <Label>First name </Label>
                                            <Input type="text" value={firstname}
                                                   onChange={e => setFirstname(e.target.value)} required/>
                                        </div>
                                        <div className="flex-1 space-y-1">
                                            <Label>Last name </Label>
                                            <Input defaultValue="" value={lastname}
                                                   onChange={e => setLastname(e.target.value)} required/>
                                        </div>
                                    </div>
                                    <div className="flex space-x-5  ">
                                        <div className="flex-1 space-y-1">
                                            <Label>Email address </Label>
                                            <Input type="email" defaultValue="" value={email}
                                                   onChange={e => setEmail(e.target.value)} required/>
                                        </div>

                                        <div className="flex-1 space-y-1">
                                            <Label>Cellphone number </Label>
                                            <Input defaultValue="" value={cell}
                                                   onChange={e => setCell(e.target.value)} required/>
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <Label>Home address </Label>
                                        <Input defaultValue="" value={address}
                                               onChange={e => setAddress(e.target.value)}/>
                                    </div>

                                    <div className="space-y-1">
                                        <Label>Any additional information you want to provide or request</Label>
                                        <Textarea placeholder="..." value={addinfo}
                                                  onChange={e => setAddinfo(e.target.value)}/>
                                        <span className="text-sm text-neutral-500 block">
                  </span>
                                    </div>
                                    {isLoading && (
                                        <div>Submitting your booking...</div>
                                    )}
                                    <div className="pt-8">
                                        <ButtonPrimary disabled={isLoading}>Confirm booking</ButtonPrimary>
                                    </div>
                                </div>
                            </form>
                            : <div className="pt-8">
                                <ButtonPrimary href="/login/blogin">Login/signup to complete booking</ButtonPrimary>
                            </div>
                    }

                </div>
            </div>
        );
    };

    return (
        <div className={`nc-CheckOutPagePageMain ${className}`}>
            <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
                <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
                <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
            </main>
        </div>
    );
};

export default CheckOutPagePageMain;
