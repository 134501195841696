import React, {FC, FormEvent, useState} from "react";

import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import {Link, useParams} from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {GoogleOAuthProvider, useGoogleLogin} from "@react-oauth/google";
import useRecaptcha from "./useRecaptcha";
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import {useAuth} from "../../context/AuthContext";
import {log} from "../../hooks/sfw";

export interface PageLoginProps {
  className?: string;
}

// Function to get user information using access token
async function getGoogleUserInfo(access_token: string) {

    return new Promise((resolve, reject) => {
        axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                    'Authorization': `Bearer ${access_token}`
                }
            })
            .then(response => {
                log('googleuserinfo:resp:', response.data);
                axios.post(process.env.REACT_APP_BACKEND_SERVER + '/api/googlelogin', response.data)
                    .then(function (response) {
                        log('api:googlelogin:resp:', response.data);
                        if (response.data) {
                            resolve(response.data);
                        }else{
                            reject('didnt get googleuser');
                        }
                    })
                    .catch(error => {
                        log('api:googlelogin:error:', error);
                        reject(error);
                    });

            })
            .catch(error => {
                console.error('googleuserinfo:error:', error);
                reject(error);
            })
    });
    /* info returned
    {
    "sub": "109456901770779827374", - uniqueid always returned the same for this user..
    "name": "Simon Taylor",
    "given_name": "Simon",
    "family_name": "Taylor",
    "picture": "https://lh3.googleusercontent.com/a/ACg8ocKT0aIq6tl_8jZpnRInnfzt4TEPf7nwZY0FDJF_5FZfBRO1tDArnA=s96-c",
    "email": "l0ft13@gmail.com",
    "email_verified": true
    }

    {
        "sub": "117522479731776152206",
        "name": "Dean Moriarty",
        "given_name": "Dean",
        "family_name": "Moriarty",
        "picture": "https://lh3.googleusercontent.com/a/ACg8ocLu2ztos1Lsiwj57nLceeHo7O4TvKY83AF4aTaguh0rbeihuw=s96-c",
        "email": "deanmor@gmail.com",
        "email_verified": true
    }

     */
}

const GoogleLoginComp = ({navTo = "/"}) => {
    const { login } = useAuth();
    const loginWithGoogle = useGoogleLogin({
        onSuccess: tokenResponse => {
            log('google:success:', tokenResponse);
            getGoogleUserInfo(tokenResponse.access_token)
                .then(aData => {
                    login(aData,navTo);
                })
                .catch(error => log(error));
        },
        onError: errorResponse => log('google:error:', errorResponse),

    });


    return (
        <button onClick={() => loginWithGoogle()}
           className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]">
            <img
                className="flex-shrink-0"
                src={googleSvg}
                alt="Continue with Google"
            />
            <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                Continue with Google
            </h3>
        </button>
    )
}


const PageLogin: FC<PageLoginProps> = ({className = ""}) => {
    const { state } = useParams<{ state: string }>();
    //log(state);
    let pageState = state;
    const { login } = useAuth();
    const {capchaToken, recaptchaRef, handleRecaptcha} = useRecaptcha();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    // const responseGoogleOk = (response: any) => {
    //     log('googleok:', response);
    //     // Handle the response from Google here
    // }
    // const responseGoogleFail = () => {
    //     log('googlefail:');
    //     // Handle the response from Google here
    // }

    const navTo = (pageState === 'blogin' || pageState === 'bsignup' ? '/checkout' : '/');
    log('navTo:',navTo);
    //doesnt bloody work....
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (capchaToken && username && password) {

            const response = await fetch(process.env.REACT_APP_BACKEND_SERVER + '/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password,
                    capchaToken,
                }),
            });

            const responseData = await response.json();
            log(responseData);
            // Check if the reCAPTCHA validation failed on the server-side
            if (responseData.success === false) {
                alert(responseData.reason);
                handleRecaptcha('');
                if (recaptchaRef.current) {
                    recaptchaRef.current.reset();
                }
                return;
            }
            // Reset captcha after submission
            recaptchaRef.current?.reset();

            if (responseData.success && responseData.user) {
                //processLogin(responseData.user);
                login(responseData,navTo);
                log('Login successful');
            } else {
                log('Login failed');
            }
        } else {
            alert('Please fill in all fields and complete the captcha.');
        }
    };

    /*<div>
        <h2>Login with Google</h2>
        <GoogleLogin
            onSuccess={responseGoogleOk}
            onError={responseGoogleFail}
        />
    </div>*/

//'737527572883-avnuc8rccn3qtl3mp3hmltm03utua5e3.apps.googleusercontent.com',// process.env.REACT_APP_GOOGLE_MAP_ID,
    const googleClientID:string = process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID  ?? 'whoops someone forgot to add the id..';
    return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || @Ballito booking system</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            {pageState === 'login' || pageState === 'blogin'
                ? 'Login'
                : 'Sign-Up'}
        </h2>
        <div className="max-w-md mx-auto space-y-6">
            <div className="grid gap-3">
                <GoogleOAuthProvider
                    clientId={googleClientID}>
                    <GoogleLoginComp navTo={navTo}/>
                </GoogleOAuthProvider>
            </div>
            {/* OR */}
            <div className="relative text-center">
            <span
                className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
                <div
                    className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            </div>
            {/* FORM */}
            <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={handleSubmit}>
                <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                username or email address
              </span>
                    <Input
                        placeholder="example@example.co.za"
                        className="mt-1"
                        value={username}
                        onChange={e => setUsername(e.target.value)} required
                    />
                </label>
                <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
                <Input type="password" className="mt-1" value={password} onChange={e => setPassword(e.target.value)}
                           required/>
                </label>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfdqRYqAAAAACih3BergiCyHyMXMvGRLUHDo2tw"
                    onChange={handleRecaptcha}
                />
                <ButtonPrimary disabled={!capchaToken} type="submit">Continue</ButtonPrimary>
            </form>

            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
                {pageState === 'login' || pageState === 'blogin'
                    ?
                    <div>New user? {` `}
                        <Link to={pageState === 'blogin' ? '/login/bsignup' : '/login/signup' }>Create an account</Link>
                    </div>
                    :
                    <div>Already have an account? {` `}
                        <Link to={pageState === 'bsignup' ? '/login/blogin' : '/login/login' }>Sign in</Link>
                    </div>
                }
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
