import React, {FC} from 'react';
import {Helmet} from "react-helmet-async";

export interface PagesInfoProps {
    heading: string;
    sections: { title: string; content?: string; bits?:any[] }[]
}

const PagesInfo: FC<PagesInfoProps> = ({ heading = "",sections = [] }) => {
    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>{heading} || @Ballito booking system</title>
            </Helmet>

            <div className="nc-SingleContent container space-y-10">
                <div
                    id="single-entry-content"
                    className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
                >

                    <div className="space-y-3 text-center flex flex-col items-center">
                        <h2 className="text-3xl font-semibold">{heading}</h2>
                    </div>

                    {sections.map((section, index) => (
                        <div className="max-w-screen-md mx-auto space-y-1" key={index}>
                            <h1 className="text-neutral-900 font-semibold text-xl md:text-xl md:!leading-[120%] lg:text-xl dark:text-neutral-100 max-w-xl "
                                    title="{section.title}">
                                {section.title}
                            </h1>
                            <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                                {section.content ? section.content : ''}
                                {section.bits ? section.bits.map((item, indx) => (
                                    <li>
                                        {item}
                                    </li>
                                )) : ''}
                            </span>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    )
}

export default PagesInfo;