import { useEffect, useState } from 'react';
import { fetchData } from './dataFetcher';
import {AuthorType} from "./types";
import {useSearch} from "../context/SearchContext";

export interface PropertyType {
    id: number;
    author: AuthorType;
    date: string;
    href: string;
    title: string;
    pp_featured_image: string;
    commentCount: number;
    viewCount: number;
    address: string;
    reviewStart: number;
    reviewCount: number;
    like: boolean;
    galleryImgs: string[];
    galleryImgs500: string[];
    galleryImgs1200: string[];
    galleryImgsFull: string[];
    price: string;
    listingCategoryName: string;
    maxGuests: number;
    bedrooms: number;
    bathrooms: number;
    saleOff?: string | null;
    //isAds: boolean | null;
    map: {
        lat: number;
        lng: number;
    };
    description:string;
    // new items to be sorted
    badges:string[];
    beds:number;
    amenities:string;
    ratesPeakSeason:number;
    ratesInSeason:number;
    ratesSemiSeason:number;
    ratesOutSeason:number;
    nightsMin:number;
    stars:number;
    reviewcount:number;
    sqm:number;
    bookings:string;
    disabledDates:Date[];
}

const generateDisabledDates = (dateRangesString: string) => {
    let dates:Date[] = [];

    if (!dateRangesString) return dates;
    const dateRanges = dateRangesString.split(',').map(range => {
        const [start, end] = range.split('|');
        return { start: new Date(start), end: new Date(end) };
    });

    dateRanges.forEach(range => {
        const start = range.start;
        const end = range.end;
        while(start <= end) {
            dates.push(new Date(start));
            start.setDate(start.getDate() + 1);
        }
    });

    return dates;
}


export const usePropertyData = (): {
    propData: PropertyType[],
    iLowestPrice: number,
    iHighestPrice: number,
    filteredProperties: PropertyType[],
    setFilteredProperties: (value: PropertyType[]) => void,
    iPLen: number,
    setPLen: any
} => {
    //console.log('pdhook:prop data comp fired..');
    const [propData, setData] = useState<PropertyType[]>([]);
    const {startDate,endDate, locationInputTo} = useSearch();
    const [iHighestPrice, setHighestPrice] = useState(0);
    const [iLowestPrice, setLowestPrice] = useState(0);
    const [filteredProperties, setFilteredPropertiesInternal] = useState<PropertyType[]>(propData);

    const [iPLen, setPLen] = useState(0);
    const setFilteredProperties = function(filteredProperties : PropertyType[]) {
        //console.log("setFilteredProperties", filteredProperties, filteredProperties.length);
        setFilteredPropertiesInternal(filteredProperties);
        setPLen(filteredProperties.length);
    }

    useEffect(() => {
        const FetchDataFromServer = async () => {
            try {
                let data = await fetchData('props');
                let iHighTemp = 0;
                let iLowTemp = 0;
                data.forEach((item: PropertyType) => {

                    if (item.ratesOutSeason < iLowTemp || iLowTemp === 0){
                        setLowestPrice(item.ratesOutSeason);
                        iLowTemp = item.ratesOutSeason;
                        //console.log('setting lowestprice',iLowTemp);
                    }
                    if (item.ratesOutSeason > iHighTemp || iHighTemp === 0){
                        setHighestPrice(item.ratesOutSeason);
                        iHighTemp = item.ratesOutSeason;
                        //console.log('setting highestprice',iHighTemp);
                    }

                    item.disabledDates = generateDisabledDates(item.bookings);
                    item.galleryImgs500 = item.galleryImgs.map(image => {
                        const splitImage = image.split(".");
                        return `https://man.atballito.co.za/unitimages/` + item.id + `/${splitImage[0]}.500.${splitImage[1]}`;
                    });
                    item.galleryImgs1200 = item.galleryImgs.map(image => {
                        const splitImage = image.split(".");
                        return `https://man.atballito.co.za/unitimages/` + item.id + `/${splitImage[0]}.1200.${splitImage[1]}`;
                    });
                    item.galleryImgsFull = item.galleryImgs.map(image => {
                        return `https://man.atballito.co.za/unitimages/` + item.id + `/` + image;
                    });
                });


                if (startDate && endDate) {
                    let filteredPropData = data.filter(property => {
                        return !property.disabledDates.some((disabledDate: Date) => {
                            disabledDate = new Date(disabledDate);
                            return disabledDate >= startDate && disabledDate <= endDate;
                        });
                    });
                    //console.log('filteredPropData',filteredPropData);
                    data = filteredPropData;
                }
                if (locationInputTo){
                    let filteredPropData = data.filter(property => {
                        return property.town === locationInputTo;
                    });
                    data = filteredPropData;
                }

                setData(data);
                setFilteredProperties(data);

            } catch (error) {
                // Handle error fetching data
                console.error('Error fetching data in component:', error);
            }
        };

        FetchDataFromServer();
    }, [startDate, endDate, locationInputTo]);

    return { propData, iLowestPrice, iHighestPrice, filteredProperties, setFilteredProperties, iPLen, setPLen };
};

