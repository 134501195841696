import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Page} from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import PrivateRoute from "./PrivateRoute";
import PagePrivacy from "../containers/PagesInfo/PagePrivacy";
import {AuthProvider} from "../context/AuthContext";
import {SearchProvider} from "../context/SearchContext";
import PageTermsAndConditions from "../containers/PagesInfo/PageTermsAndConditions";
import PageCodeOfConduct from "../containers/PagesInfo/PageCodeOfConduct";

export const pages: Page[] = [
    {path: "/", exact: true, component: PageHome},
    {path: "/#", exact: true, component: PageHome},
    //
    {path: "/listing-stay", component: ListingStayPage},
    {path: "/listing-stay-map", component: ListingStayMapPage},
    {path: "/listing-stay-detail/:id", component: ListingStayDetailPage},
    //
    //
    {path: "/checkout/:id?", component: CheckOutPage},
   // {path: "/pay-done", component: PayPage},
    //
    {path: "/author", component: AuthorPage, admin: true},
    {path: "/account", component: AccountPage, reqLogin: true},
    {path: "/account-password", component: AccountPass, reqLogin: true},
    {path: "/account-savelists", component: AccountSavelists, reqLogin: true},
    {path: "/account-billing", component: AccountBilling, admin: true},
    //
    {path: "/blog", component: BlogPage},
    {path: "/blog-single", component: BlogSingle},
    //
    {path: "/add-listing-1", component: PageAddListing1, reqLogin: true},
    {path: "/add-listing-2", component: PageAddListing2, reqLogin: true},
    {path: "/add-listing-3", component: PageAddListing3, reqLogin: true},
    {path: "/add-listing-4", component: PageAddListing4, reqLogin: true},
    {path: "/add-listing-5", component: PageAddListing5, reqLogin: true},
    {path: "/add-listing-6", component: PageAddListing6, reqLogin: true},
    {path: "/add-listing-7", component: PageAddListing7, reqLogin: true},
    {path: "/add-listing-8", component: PageAddListing8, reqLogin: true},
    {path: "/add-listing-9", component: PageAddListing9, reqLogin: true},
    {path: "/add-listing-10", component: PageAddListing10, reqLogin: true},
    //
    {path: "/contact", component: PageContact},
    {path: "/about", component: PageAbout},
    {path: "/login/:state", component: PageLogin, pageState: "login"},
    {path: "/subscription", component: PageSubcription, admin: true},
//    {path: "/tos", component: PageTermsOfService},
    {path: "/tac", component: PageTermsAndConditions},
    {path: "/privacy", component: PagePrivacy},
    {path: "/codeofconduct", component: PageCodeOfConduct},
    //
];

const MyRoutes = () => {
    let WIN_WIDTH = useWindowSize().width;
    if (typeof window !== "undefined") {
        WIN_WIDTH = WIN_WIDTH || window.innerWidth;
    }
//<PrivateRoute path="/contact" element={<PageContact/>} />
    //<PrivateRoute path="/contact" element={<PageContact />} />
    return (
        <BrowserRouter>
            <AuthProvider>
                <SearchProvider>

                    <ScrollToTop/>
                    <SiteHeader/>
                    <Routes>

                        {pages.map(({component, path, reqLogin, admin, pageState}) => {
                            const Component = component;
                            return <Route key={path} path={path} element={
                                <PrivateRoute requireLogin={reqLogin} requireAdmin={admin} state={pageState}>
                                    {<Component/>}
                                </PrivateRoute>
                            }/>;
                        })}

                        <Route element={<Page404/>}/>
                    </Routes>
                    {WIN_WIDTH < 768 && <FooterNav/>}
                    <Footer/>
                </SearchProvider>
            </AuthProvider>
        </BrowserRouter>
    );
};

export default MyRoutes;
