import React from 'react';
import { Navigate } from 'react-router-dom';
import {useAuth} from "../context/AuthContext";


interface PrivateRouteProps  {
    children: React.ReactNode;
    requireAdmin?: boolean;
    requireLogin?: boolean;
    pageState?: string;
    state? : string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, requireAdmin = false, requireLogin, pageState = false }) => {
    const { isLoggedIn, isAdmin } = useAuth();

    if (requireLogin && !isLoggedIn) {
        return <Navigate to="/login/login" state={{key: "floo"}}/>;
    }else if (requireAdmin && !isAdmin) {
        return <Navigate to="/"  state={{key: "floo"}}/>;
    }

    return <>{children}</>;
};

export default PrivateRoute;