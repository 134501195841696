import { useEffect, useState } from 'react';
import { fetchData } from './dataFetcher';

interface TownType {
    pp_town: string;
    count: number;
}

export const useTownData = (): TownType[] => {
    //console.log('tdhook:town data comp fired..');
    const [townData, setData] = useState<TownType[]>([]);
    useEffect(() => {
        const fetchDataFromServer = async () => {
            try {
                const data = await fetchData('towns');
                setData(data);
            } catch (error) {
                // Handle error fetching data
                console.error('Error fetching data in component:', error);
            }
        };
        fetchDataFromServer();
    }, []);

    return townData;
};

