import React, {ReactNode} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import MobileFooterSticky from "./MobileFooterSticky";
import {usePropertyData} from "../../data/propertiesDataHook";
import {ListingGalleryImage} from "../../components/ListingImageGallery/utils/types";
import {toNumber} from "lodash";

const DetailPagetLayout = ({children}: { children: ReactNode }) => {
    const navigate = useNavigate();
    const thisPathname = useLocation().pathname;
    //console.log(thisPathname);
    const [searchParams] = useSearchParams();
    const modal = searchParams?.get("modal");

    const {id} = useParams<{ id: string }>();
    //console.log('layoutid:',id);
    const {propData} = usePropertyData();
    //console.log('propData:',propData);
    const thisProp = propData.find(e => e.id === toNumber(id));
    //console.log('layout:thisProp:',thisProp);

    const handleCloseModalImageGallery = () => {
        let params = new URLSearchParams(document.location.search);
        params.delete("modal");
        navigate(`${thisPathname}/?${params.toString()}`);
    };

    return (
        <div className="ListingDetailPage">
            <ListingImageGallery
                isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
                onClose={handleCloseModalImageGallery}
                images={thisProp?.galleryImgs1200.map(
                    (item, index): ListingGalleryImage => {
                        return {
                            id: index,
                            url: item,
                        };
                    })}
            />

            <div className="container ListingDetailPage__content">{children}</div>

            {/* STICKY FOOTER MOBILE */}
            <MobileFooterSticky/>
        </div>
    );
};

export default DetailPagetLayout;
