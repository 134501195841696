import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React from "react";
import {SearchContextProps, useSearch} from "../../context/SearchContext";


const GuestsInput: React.FC<SearchContextProps> = (props) => {
    const {guestClassName, guestAdultsInputValue,guestChildrenInputValue,guestInfantsInputValue, guestHandleChangeData} = useSearch();

  return (
    <div className={`flex flex-col relative p-5 ${guestClassName}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        {`Who's coming?`}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={guestAdultsInputValue}
        onChange={ (guestHandleChangeData
            ? (value) => guestHandleChangeData(value, "guestAdults")
            : undefined) }
        max={20}
        label="Adults"
        desc="Ages 13 or above"
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestChildrenInputValue}
        onChange={(guestHandleChangeData
            ? (value) => guestHandleChangeData(value, "guestChildren")
            : undefined)}
        max={20}
        label="Children"
        desc="Ages 2–12"
      />

      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestInfantsInputValue}
        onChange={(guestHandleChangeData
            ? (value) => guestHandleChangeData(value, "guestInfants")
            : undefined)}
        max={20}
        label="Infants"
        desc="Ages 0–2"
      />
    </div>
  );
};

export default GuestsInput;
