import React from 'react';
import PagesInfo from "./PagesInfo";
const codeOfConduct: { title: string; content?: string; bits?:any[] }[] = [
    {
        title: "1. Introduction",
        content: `At @Ballito, we are committed to creating a positive, respectful, and safe environment for all users, including Clients and Property Owners. This Code of Conduct outlines the expected behavior and responsibilities when using our platform. By using our services, you agree to abide by this Code of Conduct.`
    },
    {
        title: "2. Respectful Communication",
        bits: [
            `Respect Others: Treat all users with respect, courtesy, and kindness. Harassment, discrimination, or any form of abusive behavior will not be tolerated.`,
            `Constructive Feedback: When providing feedback, be constructive and considerate. Avoid using offensive language or personal attacks.`
        ]
    },
    {
        title: "3. Honest and Transparent Interactions",
        bits: [
            `Honesty in Listings and Bookings: Property Owners must provide accurate and truthful information about their properties. Clients must provide accurate information during the booking process and adhere to the terms of their booking.`,
            `No Misrepresentation: Do not misrepresent your identity, qualifications, or intentions when interacting on the platform.`
        ]
    },
    {
        title: "4. Privacy and Confidentiality",
        bits: [
            `Respect Privacy: Do not share personal information of others without their explicit consent. Respect the privacy of fellow users.`,
            `Confidentiality: Any private communications between Clients and Property Owners should remain confidential unless both parties agree to disclose the information.`
        ]
    },
    {
        title: "5. Safety and Security",
        bits: [
            `Personal Safety: Clients and Property Owners should prioritize safety at all times. If you feel unsafe during an interaction or stay, report the issue immediately to our support team.`,
            `Security of Information: Protect your account information and do not share your login credentials with anyone. Report any suspicious activities or security breaches to us immediately.`
        ]
    },
    {
        title: "6. Fairness and Integrity",
        bits: [
            `No Discrimination: We do not tolerate discrimination based on race, ethnicity, nationality, religion, gender, sexual orientation, age, disability, or any other protected characteristic.`,
            `Fair Dealings: Engage in fair practices and do not take advantage of others. Property Owners and Clients should fulfill their respective obligations as agreed upon.`
        ]
    },
    {
        title: "7. Reporting Violations",
        bits: [
            `How to Report: If you witness or experience any behavior that violates this Code of Conduct, please report it to us immediately through report@atballito.co.za`,
            `Investigation: We take all reports seriously and will investigate any potential violations. Appropriate actions, including account suspension or termination, may be taken.`
        ]
    },
    {
        title: "8. Consequences of Violations",
        bits: [
            `Enforcement: Violations of this Code of Conduct may result in warnings, temporary suspensions, or permanent bans from our platform.`,
            `Legal Action: In cases of severe misconduct or illegal activities, we may involve law enforcement or pursue legal action.`
        ]
    },
    {
        title: "9. Updates to the Code of Conduct",
        content: `We may update this Code of Conduct from time to time. We will notify you of any significant changes, and your continued use of the platform will constitute acceptance of the updated terms.`
    },
    {
        title: "10. Contact Us",
        content: `If you have any questions, concerns, or need to report a violation, please contact us at legal@atballito.co.za`
    }
];


const CodeOfConduct: React.FC = () => {
    return (
        <PagesInfo heading='Code of Conduct' sections={codeOfConduct}/>
    );
};

export default CodeOfConduct;