import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import React, {FC} from "react";
import DatePicker from "react-datepicker";
import {useSearch,isDateInRange,addDays} from "../../context/SearchContext";

let dateSelError = '';

export interface StayDatesRangeInputProps {
    className?: string;
    disabledDates?: Date[];

}
const SectionDateRange: FC<StayDatesRangeInputProps> = ({
       className = "flex-1",disabledDates = []
   }) => {
//const SectionDateRange = ({disabledDates}) => {
  const { startDate,
      setStartDate,
      endDate,
      setEndDate } = useSearch();

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    if (start && end) {
        if (disabledDates.some(date => isDateInRange(start, end, date))) {
            if (setStartDate)   setStartDate(null);
            if (setEndDate)     setEndDate(null);
            dateSelError = 'The selected range includes unavailable dates. Please select again!';
        } else {
            dateSelError = '';
            if (setStartDate)   setStartDate(start);
            if (setEndDate)     setEndDate(end);
        }
    } else {
        dateSelError = '';
        if (setStartDate)   setStartDate(start);
        if (setEndDate)     setEndDate(end);
    }
  };

  const renderSectionCheckIndate = () => {

    return (
        <div className="listingSection__wrap overflow-hidden">
            {/* HEADING */}
            <div>
                <h2 className="text-2xl font-semibold">Availability</h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices vary on different dates due to seasonal rates
          </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <span className="block mt-2 text-red-400 dark:text-red-400">
                {dateSelError}
            </span>
            <div className="">
                <DatePicker
                    selected={startDate}
                    onChange={onChangeDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    monthsShown={2}
                    showPopperArrow={false}
                    excludeDates={disabledDates}
                    minDate={addDays(new Date(),1)}
                    inline
                    renderCustomHeader={(p) => (
                        <DatePickerCustomHeaderTwoMonth {...p} />
                    )}
                    renderDayContents={(day, date) => (
                        <DatePickerCustomDay dayOfMonth={day} date={date}/>
                    )}
                />
            </div>
        </div>
    );
  };

    return renderSectionCheckIndate();
};

export default SectionDateRange;
