import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";

const AccountBilling = () => {
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Payments & payouts</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="max-w-2xl">
            <span className="text-xl font-semibold block">Payout methods</span>
            <br />
            <span className="text-neutral-700 dark:text-neutral-300 block">
             We will pay you when we feel like it..
            </span>
            <div className="pt-10">
              <ButtonPrimary>Add payout mothod</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountBilling;
