import React, {FC} from "react";
import {useAuth} from "../../context/AuthContext";

export interface BtnLikeIconProps {
    classNameLike?: string;
    classNameShare?: string;
    showLike?: boolean;
    showShare?: boolean;
    colorClass?: string;
    id?: number;
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
       classNameLike = "absolute right-3 top-3",
       classNameShare = "absolute right-12 top-3",
       showLike  = true,
       showShare = false,
       colorClass = "text-white bg-black bg-opacity-30 hover:bg-opacity-50",
       id = 0,
   }) => {
    const {toggleLike,isLiked} = useAuth();
    let likedState = isLiked(id);
    //const [likedState, setLikedState] = useState(isLiked);
    //const {aLikes} = useAuth();
    return (
        <div>
            {showShare ?
            <div
                className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer  nc-BtnLikeIcon--liked ${colorClass} ${classNameShare}`}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"/>
                </svg>
            </div>
                : ''}
            {showLike ?
            <div
                className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${
                    likedState ? "nc-BtnLikeIcon--liked" : ""
                }  ${colorClass} ${classNameLike}`}
                data-nc-id="BtnLikeIcon"
                title="Save"
                onClick={() => toggleLike(id)}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill={likedState ? "currentColor" : "none"}
                    viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
                </svg>
            </div>
                : ''}
        </div>
    );
};

export default BtnLikeIcon;
