import React, {createContext, useState, ReactNode, useContext, useEffect} from 'react';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import {NavItemType} from "../shared/Navigation/NavigationItem";
import avatar1 from "images/avatar.jpg";
import {log} from "../hooks/sfw";

interface UserProps {
    id: number;
    username: string;
    email: string;
    phone: string;
    firstname: string;
    lastname: string;
    image: string;
    admin: boolean;
    dob: string;
    address: string;
    bio: string;
    sociallogin: boolean;
}
//assuming this is the structure of your initial user
let initialUser: UserProps = {
    id: 0,
    username: "",
    email: "",
    phone: "",
    firstname: "",
    lastname: "",
    image: avatar1,
    admin: false,
    dob: "",
    address: "",
    bio: "",
    sociallogin: true
}
interface AuthContextProps {
    isLoggedIn: boolean;
    isAdmin: boolean;
    login: (aUser:any,navTo:string) => void;
    logout: () => any;
    aUser: UserProps;
    aLikes: number[];
    toggleLike: (id:number) => void;
    isLiked: (id:number) => boolean;
    shouldItemShow: any;
    jwt: string;
    setUser: any;
    setupToken:(jwt:string) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);


export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [aUser,setUser] = useState<UserProps>(initialUser);
    const [aLikes,setLikes] = useState<number[]>([]);
    const toggleLike = (id:number) => {
        log('called ' + id,aLikes);
        if (id) {
            let aLT: number[] = aLikes;
            const index = aLT.indexOf(id);
            if (index > -1) {
                aLT = [...aLT.slice(0, index), ...aLT.slice(index + 1)];
            } else {
                aLT = [...aLT, id];
            }
            setLikes(aLT);
            localStorage.setItem('likes', JSON.stringify(aLT));
            log(aLT);
        }
    }

    const isLiked = (id:number) => {
        let bRet = false;
        if (id) {
            const index = aLikes.indexOf(id);
            bRet = (index > -1);
        }
        return bRet;
    }

    const [jwt,setJWT] = useState('');
    const navigate = useNavigate();

    const setupToken = (jwtoken: string) => {
        localStorage.setItem('token', jwtoken);
        setJWT(jwtoken);
        log('authcontext:setjwt:',jwt);
    };

    const shouldItemShow = (item: NavItemType) => {
        let bShow = false;
        if (isLoggedIn){
            if (!item.hideifloggedin) {
                bShow = !(item.admin && !isAdmin);
            }
        }else{
            if (!item.admin) {
                bShow = !item.protected;
            }
        }
        return bShow;
    };

    const login = (aData: any, navTo:string) => {
        // check if we have a token and a user..
        if (aData.jwt && aData.user){
            setupToken(aData.jwt);
            //log('authcontext:login:jwt:',jwt);
            localStorage.setItem('userID', aData.user.id);
            setIsLoggedIn(true);
            setUser(aData.user);
            navigate(navTo);
        }
        //setIsAdmin(admin);
    };

    const logout = () => {
        log('logout:doit');
        localStorage.removeItem('token');
        localStorage.removeItem('userID');
        setIsLoggedIn(false);
        setIsAdmin(false);
    };

    useEffect(() => {
        log('authcontext:useeffect:');

        const userID = localStorage.getItem('userID');
        const jwtoken = localStorage.getItem('token');

        let sLikes = localStorage.getItem('likes');
        setLikes((sLikes ? JSON.parse(sLikes) :[]));

        setJWT(jwtoken ?? '');
        log('authcontext:jwtoken:',jwtoken);
        log('authcontext:jwt:',jwt);
        log('authcontext:usid:',userID);
        if(jwtoken && userID) {
            // lets send the token to get it verified
            axios.post(process.env.REACT_APP_BACKEND_SERVER + '/api/jwt',
                {userID: userID, token: jwtoken}
                )
                .then(function (response) {
                    log('api:jwt:resp1:', response.data);
                    if (response.data.success) {
                        login(response.data,'/');
                    }else{
                        logout();
                    }
                })
                .catch(error => {
                    log('api:jwt:error:', error);
                    logout();
                });
        }
    }, []);

    return (
        <AuthContext.Provider value={{aUser, aLikes, isLiked, toggleLike, isLoggedIn, isAdmin, login, logout, shouldItemShow, jwt, setUser, setupToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
