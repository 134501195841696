import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import moment from 'moment';
import { toInteger, toNumber} from "lodash";
import {PropertyType} from "../data/propertiesDataHook";
import {SeasonsType, useSeasonsData} from "../data/seasonsDataHook";

export interface GuestsObject {
    guestAdults?: number;
    guestChildren?: number;
    guestInfants?: number;
}
export interface SearchContextProps {
    locationInputTo?: string | undefined;
    setLocationInputTo?: (locationInputTo:string) => void;
    guestInput?:GuestsObject,
    setGuestInput?:(guestInput:GuestsObject) => void,
    startDate?: Date | null;
    setStartDate?: (startDate:Date | null) => void;
    endDate?: Date | null;
    setEndDate?: (startDate:Date | null) => void;
    guestAdultsInputValue?:number;
    guestChildrenInputValue?:number;
    guestInfantsInputValue?:number;
    guestHandleChangeData?: (value: number, type: keyof GuestsObject) => void;
    guestOnChange?: (data: GuestsObject) => void;
    guestClassName?: string;
    stayNights?: number;
    aDays?: DaysResult;
    bookProperty?: PropertyType | undefined,
    setBookProperty?: (property: PropertyType | undefined ) => void;
}


// Create the date context
const SearchContext = createContext<SearchContextProps | undefined>(undefined);

function GetNextFriday(plusDays?:number){
    const today = new Date();
// Get day of the week: 0 (Sunday) through 6 (Saturday)
    const currentDay = today.getDay();
// Number of days until next Friday (5)
    let daystoNextFriday = 5 - currentDay;
// If today is after Friday, add 7 days
    if (daystoNextFriday <= 0) {
        daystoNextFriday += 7;
    }
    if (plusDays){
        daystoNextFriday += plusDays;
    }
// Get next Friday date
    const nextFriday = new Date();
    nextFriday.setDate(today.getDate() + daystoNextFriday);
    return nextFriday;
}

function getDaysDifference(date1:Date, date2:Date) {
    // One day in milliseconds
    const oneDay = 24 * 60 * 60 * 1000;

    // Calculate the difference in milliseconds
    const diffInMS = Math.abs(date2.getTime() - date1.getTime());

    // Convert back to days and return
    return Math.floor(diffInMS / oneDay);
}


interface DaysResult {
    out: number;
    semi: number;
    in: number;
    peak: number;
}
function calculateDays(aSeasons: SeasonsType[], rangeStart: any, rangeEnd: any) {
    //console.log(periods);console.log(range);
    rangeStart = moment(rangeStart);
    rangeEnd = moment(rangeEnd);
    let result: DaysResult = {out:0, semi:0, in:0, peak:0};

    aSeasons.forEach((period, index) => {
        const periodStart = moment(period.start);
        const periodEnd = moment(period.end);
        if (periodStart.isSameOrBefore(rangeEnd) && periodEnd.isSameOrAfter(rangeStart)) {
            const start = periodStart.isSameOrBefore(rangeStart) ? rangeStart : periodStart;
            let end = periodEnd.isSameOrAfter(rangeEnd) ? rangeEnd : periodEnd;

            //console.log('start',start);
            //console.log('end',end);
            result[period.desc] += end.diff(start, 'days');
        }
    });

    return result;
}

export const calculateTotalForNights = (aDays: DaysResult,thisProp: PropertyType) => {
    let iDaysTotal = 0;
    if (aDays?.out && aDays?.out > 0){
        iDaysTotal += (toInteger(thisProp?.ratesOutSeason ?? 0) * (aDays?.out));
    }
    if (aDays?.semi && aDays?.semi > 0){
        iDaysTotal += (toInteger(thisProp?.ratesSemiSeason ?? 0) * (aDays?.semi));
    }
    if (aDays?.in && aDays?.in > 0){
        iDaysTotal += (toInteger(thisProp?.ratesInSeason ?? 0) * (aDays?.in));
    }
    if (aDays?.peak && aDays?.peak > 0){
        iDaysTotal += (toInteger(thisProp?.ratesPeakSeason ?? 0) * (aDays?.peak));
    }
    return iDaysTotal;
}

export const calculateServiceFees = (iDaysTotal: number) => {
    let iService = 0;
    iService += toInteger(process.env.REACT_APP_SERVICE_CHARGE ?? 350);
    iService += toInteger((toNumber(process.env.REACT_APP_SERVICE_PCNT ?? 0.05 * 100) * iDaysTotal));
    return iService;
}



export const QuoteSummaryDisplay = ({
        aDays = {out:0, semi:0, in:0, peak:0},
        thisProp
    }: {aDays?: DaysResult, thisProp?: PropertyType}) => {
    let iDaysTotal = (aDays && thisProp ? calculateTotalForNights(aDays,thisProp) : 0);
    let iService = calculateServiceFees(iDaysTotal);
    return (
    <div className="flex flex-col space-y-4">
        {aDays.out > 0
            ?
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>R {thisProp?.ratesOutSeason.toLocaleString(undefined, {maximumFractionDigits: 0})} x {aDays?.out} nights Out of Season</span>
            <span>R {(toInteger(thisProp?.ratesOutSeason ?? 0) * (aDays?.out)).toLocaleString(undefined, {maximumFractionDigits: 0})}</span>
        </div>
        : ''
        }
        {aDays?.semi && aDays?.semi > 0
        ?
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>R {thisProp?.ratesSemiSeason.toLocaleString(undefined, {maximumFractionDigits: 0})} x {aDays?.semi} nights Semi Season</span>
            <span>R {(toInteger(thisProp?.ratesSemiSeason ?? 0) * (aDays?.semi)).toLocaleString(undefined, {maximumFractionDigits: 0})}</span>
        </div>
        : ''
        }
        {aDays?.in && aDays?.in > 0
        ?
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>R {thisProp?.ratesInSeason.toLocaleString(undefined, {maximumFractionDigits: 0})} x {aDays?.in} nights In Season</span>
            <span>R {(toInteger(thisProp?.ratesInSeason ?? 0) * (aDays?.in)).toLocaleString(undefined, {maximumFractionDigits: 0})}</span>
        </div>
        : ''
        }
        {aDays?.peak && aDays?.peak > 0
        ?
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>R {thisProp?.ratesPeakSeason.toLocaleString(undefined, {maximumFractionDigits: 0})} x {aDays?.peak} nights Peak Season</span>
            <span>R {(toInteger(thisProp?.ratesPeakSeason ?? 0) * (aDays?.peak)).toLocaleString(undefined, {maximumFractionDigits: 0})}</span>
        </div>
        : ''
        }
    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
        <span>System fees</span>
        <span>R {iService.toLocaleString(undefined, {maximumFractionDigits: 0})}</span>
    </div>
    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>R {(iDaysTotal + iService).toLocaleString(undefined, {maximumFractionDigits: 0})}</span>
        </div>
    </div>
    )};



// Provider component
export const SearchProvider = ({ children, guestOnChange}: {
    children: ReactNode,
    guestOnChange?: (data: GuestsObject) => void,
}) => {

    useEffect(() => {
        const storedStartDate = localStorage.getItem('search.startDate');
        if (storedStartDate) {
            const restoredStartDate = new Date(Number(storedStartDate));
            setStartDateN(restoredStartDate);
        }
        const storedEndDate = localStorage.getItem('search.endDate');
        if (storedEndDate) {
            const restoredEndDate = new Date(Number(storedEndDate));
            setEndDateN(restoredEndDate);
        }
        const storedLocationInputTo = localStorage.getItem('search.locationInputTo');
        setLocationInputTo(storedLocationInputTo ?? '');
        const storedGuestAdultsInputValue = localStorage.getItem('search.guestAdultsInputValue');
        setGuestAdultsInputValue(storedGuestAdultsInputValue
            ? Number(storedGuestAdultsInputValue)
            : 1);
        const storedGuestChildrenInputValue = localStorage.getItem('search.guestChildrenInputValue');
        setGuestChildrenInputValue(storedGuestChildrenInputValue
            ? Number(storedGuestChildrenInputValue)
            : 0);
        const storedGuestInfantsInputValue = localStorage.getItem('search.guestInfantsInputValue');
        setGuestInfantsInputValue(storedGuestInfantsInputValue
            ? Number(storedGuestInfantsInputValue)
            : 0);
    }, []);

    const aSeasons = useSeasonsData();

    let nextFriday = GetNextFriday();
    //console.log('searchcontext:nf:',nextFriday);
    let End = GetNextFriday(10);
    //console.log('searchcontext:end:',End);

    const [startDate, setStartDateN] = useState<Date | null>(nextFriday);
    const setStartDate = (setDate: Date | null) => {
        if (setDate) localStorage.setItem('search.startDate', setDate.getTime().toString());
        setStartDateN(setDate);
        //console.log('start date:'+moment(setDate).format('YYYY-MM-DD'));
    };

    const [endDate, setEndDateN] = useState<Date | null>(End);
    const setEndDate = (setDate: Date | null) => {
        if (setDate) localStorage.setItem('search.endDate', setDate.getTime().toString());
        setEndDateN(setDate);
    };
    const stayNights = (startDate && endDate ? getDaysDifference(startDate,endDate) : 0);

    //console.log(aSeasons);
    const aDays = calculateDays(aSeasons, startDate, endDate);
    //console.log(aDays);

    const [locationInputTo, setLocationInputToN] = useState<string | undefined>("");
    const setLocationInputTo = (setLocation: string) => {
        if (setLocation) localStorage.setItem('search.locationInputTo', setLocation);
        setLocationInputToN(setLocation);
    };
    const [guestInput, setGuestInput] = useState<GuestsObject>({
        guestAdults: 1,
        guestChildren: 0,
        guestInfants: 0,
    });
    const [guestAdultsInputValue, setGuestAdultsInputValueN] = useState(1);
    const setGuestAdultsInputValue = (setNum: number) => {
        //console.log('searchcontext:setguestadult:',setNum);
        localStorage.setItem('search.guestAdultsInputValue', setNum.toString());
        setGuestAdultsInputValueN(setNum);
    };
    const [guestChildrenInputValue, setGuestChildrenInputValueN] = useState( 0);
    const setGuestChildrenInputValue = (setNum: number) => {
        localStorage.setItem('search.guestChildrenInputValue', setNum.toString());
        setGuestChildrenInputValueN(setNum);
    };
    const [guestInfantsInputValue, setGuestInfantsInputValueN] = useState( 0);
    const setGuestInfantsInputValue = (setNum: number) => {
        localStorage.setItem('search.guestInfantsInputValue', setNum.toString());
        setGuestInfantsInputValueN(setNum);
    };
    const [bookProperty, setBookProperty] = useState<PropertyType | undefined>();
    const guestClassName= "flex-1";

    const guestHandleChangeData = (value: number, type: keyof GuestsObject) => {
        //console.log('sc:ghc:',type,value);
        let newValue = {
            guestAdults: guestAdultsInputValue,
            guestChildren: guestChildrenInputValue,
            guestInfants: guestInfantsInputValue,
        };
        if (type === "guestAdults") {
            setGuestAdultsInputValue(value);
            newValue.guestAdults = value;
        }
        if (type === "guestChildren") {
            setGuestChildrenInputValue(value);
            newValue.guestChildren = value;
        }
        if (type === "guestInfants") {
            setGuestInfantsInputValue(value);
            newValue.guestInfants = value;
        }
        guestOnChange && guestOnChange(newValue);
    };
    return (
        <SearchContext.Provider value={{
            guestClassName,
            guestAdultsInputValue,
            guestChildrenInputValue,
            guestInfantsInputValue,
            guestHandleChangeData,
            guestInput,
            setGuestInput,
            locationInputTo,
            setLocationInputTo,
            startDate,
            setStartDate,
            endDate,
            setEndDate,
            stayNights,
            aDays,
            bookProperty,
            setBookProperty
        }}>
            {children}
        </SearchContext.Provider>
    );
};


export const useSearch = (): SearchContextProps => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const addDays = (date: Date, days: number) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};
export const isDateInRange = (start: Date, end: Date, dateToCheck: Date) => {
    return (
        dateToCheck >= start &&
        dateToCheck <= end
    );
};