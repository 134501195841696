import React from "react";
import { ReactNode } from "react";
import {useSearch} from "../../context/SearchContext";
import {usePropertyData} from "../../data/propertiesDataHook";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading = "Stays in Ballito",
  subHeading,
}) => {

  const {endDate,
      startDate,
      locationInputTo,
      guestChildrenInputValue,
      guestAdultsInputValue,
      guestInfantsInputValue} = useSearch();

  let {propData} = usePropertyData();
  //console.log(propData);
  heading = locationInputTo ?? "all properties";

  const totalGuests = (guestChildrenInputValue ?? 0) + (guestAdultsInputValue ?? 0) + (guestInfantsInputValue ?? 0);
  return (
    <div className={`mb-12 lg:mb-16 ${className}`}>
      <h2 className="text-4xl font-semibold">Stays in {heading}</h2>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          {propData.length} stays
          <span className="mx-2">·</span>
            {(startDate
                ? startDate.toLocaleDateString('en-US', {  month: 'short', day: 'numeric' })
                : '')} - {
            (endDate
                ? endDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                : '') }
          <span className="mx-2">·</span>{totalGuests} Guests
        </span>
      )}
    </div>
  );
};

export default Heading2;
